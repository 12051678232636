import { useEffect } from 'react';
import { useAuth } from '@auth';
import { ScreenDefinition } from '@navigate';
import { Route } from '@types';
import { Dialog } from '@app/_ui-kit';
import { useToggle } from '@app/hooks';
import { SheetBlueprint } from '@app/blueprints';
import { useDevAlias } from '@app/dev';

/**
 * Redirects to the Auth0 signup
 */
const RegisterComponent = () => {
  const { signup } = useAuth();
  const { shouldPrompt, createAlias, generateAlias } = useDevAlias();
  const prompt = useToggle();

  useEffect(() => {
    if (shouldPrompt) {
      prompt.open();
    } else {
      signup(generateAlias());
    }
  }, []);

  const handleAutoSignup = (fallback?: string) => {
    const alias = createAlias(fallback);
    signup(alias);
  };

  // @todo - what do we want to show here (it should be pretty quick)
  return (
    <Dialog isOpen={prompt.isOpen} onRequestClose={() => {}}>
      <SheetBlueprint
        title="Select options"
        actions={[
          {
            testID: 'cms',
            type: 'CUSTOM',
            label: 'CMS Testing',
            onAction: () => handleAutoSignup('cms'),
          },
          {
            testID: 'ga',
            type: 'CUSTOM',
            label: 'GA Testing',
            onAction: () => handleAutoSignup('ga'),
          },
          {
            testID: 'cypress',
            type: 'CUSTOM',
            label: 'Automated Testing',
            onAction: () => handleAutoSignup('cypress'),
          },
          {
            testID: 'archetype',
            type: 'CUSTOM',
            label: 'Archetype',
            onAction: () => handleAutoSignup('archetype'),
          },
          {
            testID: 'someone-else',
            type: 'CUSTOM',
            label: 'Someone else',
            onAction: () => handleAutoSignup(),
          },
        ]}
      />
    </Dialog>
  );
};

export const RegisterView: ScreenDefinition = {
  name: Route.REGISTER,
  component: RegisterComponent,
  guest: true,
  options: {
    title: 'Get started',
    largeTitle: true,
    drawBehindNav: true,
  },
};
